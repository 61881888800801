import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeActivitySkeleton } from "./TypeActivity";
import type { TypeArticleSkeleton } from "./TypeArticle";
import type { TypeExternalPageSkeleton } from "./TypeExternalPage";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeProjectFields = {
  name?: EntryFieldTypes.Symbol;
  shortTitle?: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  link?: EntryFieldTypes.EntryLink<TypeArticleSkeleton | TypeExternalPageSkeleton | TypePageSkeleton>;
  type: EntryFieldTypes.Symbol<"Engagement" | "General" | "Policy" | "Research" | "Statement" | "Tool">;
  themeOrTopic?: EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>;
  currentYear?: EntryFieldTypes.Symbol;
  currentPhase?: EntryFieldTypes.Symbol;
  currentStage?: EntryFieldTypes.Symbol<"Company assessment and progress evaluation" | "Company letters sent and compiling responses" | "Investor sign-on period" | "Investor-led engagement dialogues" | "Progress report published">;
  keyDates?: EntryFieldTypes.Object;
  activeProject?: EntryFieldTypes.Boolean;
  active?: EntryFieldTypes.Boolean;
  promo?: EntryFieldTypes.EntryLink<TypeActivitySkeleton>;
};

export type TypeProjectSkeleton = EntrySkeletonType<TypeProjectFields, "project">;
export type TypeProject<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeProjectSkeleton, Modifiers, Locales>;

export function isTypeProject<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeProject<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "project";
}
