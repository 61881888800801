
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as search40azIuQO_45V5O416T2UUEtXxMQd2LugEuEKr9RgZDQWkMeta } from "/opt/build/repo/pages/search.vue?macro=true";
import { default as _91_46_46_46slug_93bxqMJRabVdWZXuvF9FWZmMIujcSvRxUBxs5JzQp4EB0Meta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as index8xoVnVnRZ_kyMH7QwvT3ZfFyN62Xk1mA8ppzVlBL6wkMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as toolsgrbFSLIbXwwnZDP1coGX0ElNr_AMaXc1idZNcI4rAvQMeta } from "/opt/build/repo/pages/dashboard/tools.vue?macro=true";
import { default as themesCe8ng9UV8agmmKLzZySRPzNpLz0DYeYJ9NAhb6xnElkMeta } from "/opt/build/repo/pages/dashboard/themes.vue?macro=true";
import { default as bookmarks0wkOoc_rSglySX8svbSmFw7etkksjwpNZLKw8AeeZuIMeta } from "/opt/build/repo/pages/dashboard/bookmarks.vue?macro=true";
import { default as companieszSKRJ1G_457Ykr1sxQt3GFAw_BBXSQShPLH3i9wedwKrIMeta } from "/opt/build/repo/pages/dashboard/companies.vue?macro=true";
import { default as statementsmIyEiaOsNPcId6rJtYMxKrlhqVU8PV47CKmeQxuPWdkMeta } from "/opt/build/repo/pages/dashboard/statements.vue?macro=true";
import { default as engagementsxcIwLe123UcRq4jbo9GrD1atC06QNzEwY_UC3VWquecMeta } from "/opt/build/repo/pages/dashboard/engagements.vue?macro=true";
import { default as dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as set_45password3dh_45HmJXsUgX4kuFN0h1y4GEFkqGw2CqRRGloThKrBkMeta } from "/opt/build/repo/pages/set-password.vue?macro=true";
import { default as reset_45password2S4NlOq3P1ZR9XrtNBf0_GEwuTrW3VEUJcKJFtfVRKcMeta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as _91slug_93bJZT3d9gVTLd_IyxvAK_45HEMyMNzj9gKGmZqqf_45UM44MMeta } from "/opt/build/repo/pages/about/our-team/[slug].vue?macro=true";
import { default as indexbG3FnWiyL_QIW82ZpzVo_4528uQmoBCDD7yLGutO47Z2EMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue?macro=true";
import { default as printaIQHUzTFMBM9Z3JCrc3glKNiptwkY7KGDjtgKnSVUX8Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue?macro=true";
import { default as downloads8cmaiEOnZW_Z5cMZlzLwHwPozJ0GRNKxxkTVGTBV1R4Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue?macro=true";
import { default as animal_45pharmatY0MtVTiOERHWOiJeFm6q7upN8Ru9hDlh9W3PiZjEIgMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue?macro=true";
import { default as meat_45sourcingFf7Dxu00b_bYQfPdIMHax2oozF8U7ndAhW65fqnU6k8Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue?macro=true";
import { default as waste_45pollutionClWSmYZ4Q_xjmHCBDHqbSLj8rTYwGTR3SnL4f45rqdwMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue?macro=true";
import { default as climate_45risk_45toolDFOLPvcuWoD4ktOR_PkjN1Pi1giAnxcNgT4F2KXkSEwMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue?macro=true";
import { default as working_45conditionsA_k8SCjhV67xhQzgi9sfl1RIxGAD2Gbm4cJeJGo9oxEMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue?macro=true";
import { default as seafood_45traceabilityDIzDTf_45e8RXxOCiLRUshGLSy3bdaJ3wFUJdUnOyK0N8Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/seafood-traceability.vue?macro=true";
import { default as sustainable_45proteins3q99itcr7id4PGzb9l2J6EsFt_MNQ6D2_45RbvCA_45qWKQMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue?macro=true";
import { default as supply_45chain_45analysisQOPMtZkL6cceiyvu90Qca2o33deQBE_XQUprnWBk_45fIMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/supply-chain-analysis.vue?macro=true";
import { default as protein_45producer_45index477CLV9c751ExGTOwTwMrG2BQshuE0V70f9y2b1zy44Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue?macro=true";
import { default as restaurant_45antibioticsD8ZnyuxvQDaV313uCe2hursFzUCZy4VMLJR62PiKvYoMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue?macro=true";
import { default as antibiotics_45stewardshiplpDc2FWpvv78hOpwUtdIRrS6_45UXpIYpsC88i5MB3L6kMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue?macro=true";
import { default as protein_45diversificationBxoUWSkET9w6l2PQAm8hfB6MuIWMCnmpwdr74Vz_45AGwMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue?macro=true";
import { default as _91name_93ujPM5cDPCG21aEg5b4K4JIQdZKPSg_7Z1144LfhAkJsMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name].vue?macro=true";
import { default as _91_46_46_46slug_93D84XsRnyonBIDFEeCZ_Cdyy9fMDTexmKdXIraU5u__UMeta } from "/opt/build/repo/pages/resources/video-hub/player/[...slug].vue?macro=true";
export default [
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/build/repo/pages/search.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta?.name,
    path: "/dashboard",
    meta: dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-tools",
    path: "tools",
    component: () => import("/opt/build/repo/pages/dashboard/tools.vue")
  },
  {
    name: "dashboard-themes",
    path: "themes",
    component: () => import("/opt/build/repo/pages/dashboard/themes.vue")
  },
  {
    name: "dashboard-bookmarks",
    path: "bookmarks",
    component: () => import("/opt/build/repo/pages/dashboard/bookmarks.vue")
  },
  {
    name: "dashboard-companies",
    path: "companies",
    component: () => import("/opt/build/repo/pages/dashboard/companies.vue")
  },
  {
    name: "dashboard-statements",
    path: "statements",
    component: () => import("/opt/build/repo/pages/dashboard/statements.vue")
  },
  {
    name: "dashboard-engagements",
    path: "engagements",
    component: () => import("/opt/build/repo/pages/dashboard/engagements.vue")
  }
]
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45password3dh_45HmJXsUgX4kuFN0h1y4GEFkqGw2CqRRGloThKrBkMeta || {},
    component: () => import("/opt/build/repo/pages/set-password.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password2S4NlOq3P1ZR9XrtNBf0_GEwuTrW3VEUJcKJFtfVRKcMeta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue")
  },
  {
    name: "about-our-team-slug",
    path: "/about/our-team/:slug()",
    component: () => import("/opt/build/repo/pages/about/our-team/[slug].vue")
  },
  {
    name: _91name_93ujPM5cDPCG21aEg5b4K4JIQdZKPSg_7Z1144LfhAkJsMeta?.name,
    path: "/resources/companies-assessed/:name()",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name].vue"),
    children: [
  {
    name: "resources-companies-assessed-name",
    path: "",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue")
  },
  {
    name: "resources-companies-assessed-name-print",
    path: "print",
    meta: printaIQHUzTFMBM9Z3JCrc3glKNiptwkY7KGDjtgKnSVUX8Meta || {},
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue")
  },
  {
    name: "resources-companies-assessed-name-downloads",
    path: "downloads",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue")
  },
  {
    name: "resources-companies-assessed-name-animal-pharma",
    path: "animal-pharma",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue")
  },
  {
    name: "resources-companies-assessed-name-meat-sourcing",
    path: "meat-sourcing",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue")
  },
  {
    name: "resources-companies-assessed-name-waste-pollution",
    path: "waste-pollution",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue")
  },
  {
    name: "resources-companies-assessed-name-climate-risk-tool",
    path: "climate-risk-tool",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue")
  },
  {
    name: "resources-companies-assessed-name-working-conditions",
    path: "working-conditions",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue")
  },
  {
    name: "resources-companies-assessed-name-seafood-traceability",
    path: "seafood-traceability",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/seafood-traceability.vue")
  },
  {
    name: "resources-companies-assessed-name-sustainable-proteins",
    path: "sustainable-proteins",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue")
  },
  {
    name: "resources-companies-assessed-name-supply-chain-analysis",
    path: "supply-chain-analysis",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/supply-chain-analysis.vue")
  },
  {
    name: "resources-companies-assessed-name-protein-producer-index",
    path: "protein-producer-index",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue")
  },
  {
    name: "resources-companies-assessed-name-restaurant-antibiotics",
    path: "restaurant-antibiotics",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue")
  },
  {
    name: "resources-companies-assessed-name-antibiotics-stewardship",
    path: "antibiotics-stewardship",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue")
  },
  {
    name: "resources-companies-assessed-name-protein-diversification",
    path: "protein-diversification",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue")
  }
]
  },
  {
    name: "resources-video-hub-player-slug",
    path: "/resources/video-hub/player/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/resources/video-hub/player/[...slug].vue")
  }
]