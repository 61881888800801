export type TypeFormDynamicFields = readonly {
  type: HTMLInputElement["type"];
  name: HTMLInputElement["name"];
  label: string;
  value?: boolean | string;
  required?: boolean;
  placeholder?: string;
  options?: string[] | "countries";
  blankOption?: boolean | string;
}[];

export function isTypeFormDynamicFields(arr: unknown): arr is TypeFormDynamicFields {
  return Array.isArray(arr) && arr.every((obj) => !!(obj && typeof obj === "object" && "type" in obj && "name" in obj && "label" in obj));
}
