<script setup lang="ts">
import type { TypeFormDynamicFields } from "~/types/TypeFormDynamicFields";
import salesforceCountries from "~/lib/salesforce/countries";

defineProps<{ formEndpoint: string; formFields: TypeFormDynamicFields }>();

const emit = defineEmits(["success"]);

const pending = ref(false);

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      emit("success");
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    throw error;
  }

  turnstile.value?.reset();

  pending.value = false;
};

const turnstile = ref();

const turnstileToken = ref("");
</script>

<template>
  <form @submit.prevent="handle">
    <input
      type="hidden"
      name="form_endpoint"
      :value="formEndpoint"
    />
    <div class="flex flex-col gap-3">
      <div
        v-for="field in formFields"
        :key="field.name"
        class="form-floating"
      >
        <select
          v-if="field.type === 'select'"
          :id="`${field.name}-input`"
          :name="field.name"
          :placeholder="field.label"
          :aria-label="field.label"
          :required="field.required"
        >
          <option
            v-if="'blankOption' in field"
            value=""
            selected
          >
            {{ typeof field.blankOption === "string" ? field.blankOption : "" }}
          </option>
          <template v-if="field.options === 'countries'">
            <option
              v-for="country in salesforceCountries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </template>
          <template v-else>
            <option
              v-for="option in field.options"
              :key="option"
              :selected="field.value === option"
            >
              {{ option }}
            </option>
          </template>
        </select>
        <textarea
          v-else-if="field.type === 'textarea'"
          :id="`${field.name}-input`"
          :name="field.name"
          :placeholder="field.label"
          :aria-label="field.label"
          :required="field.required"
          :v-bind="(typeof field.value === 'string' ? { value: field.value } : {})"
        />
        <input
          v-else
          :id="`${field.name}-input`"
          :type="field.type"
          :name="field.name"
          :placeholder="field.label"
          :aria-label="field.label"
          :required="field.required"
          :v-bind="(typeof field.value === 'string' ? { value: field.value } : {})"
        />
        <label :for="`${field.name}-input`">{{ field.label }}</label>
      </div>
      <NuxtTurnstile
        ref="turnstile"
        v-model="turnstileToken"
        :options="{
          appearance: 'interaction-only',
        }"
        class="flex justify-center"
      />
      <div class="flex justify-center">
        <ClientOnly>
          <button
            class="btn btn--icon btn--email"
            :disabled="!turnstileToken || pending"
          >
            Submit
          </button>
        </ClientOnly>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
textarea {
  height: 300px;
}
</style>
