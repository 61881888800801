import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/opt/build/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/opt/build/repo/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_w8gOGEJbCd_zll3tePMHpSlPcNH1LmVhU_SfzQ0BgV4 from "/opt/build/repo/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4 from "/opt/build/repo/plugins/click-outside.ts";
import router_scroll_client_E0tcc_fkye7v1ZcB1kkZiNJryJupTs7MSej_ltDkW88 from "/opt/build/repo/plugins/router-scroll.client.ts";
import swiper_client_NrOBMhFIWYHsdM_q16aZBFnWaXK9VtE1Z_V7xAdrtO0 from "/opt/build/repo/plugins/swiper.client.ts";
import v_tooltip_GcZrMDzhZrP65RfEXeZdHDIsPaRz4FoykeN4qcrCdxo from "/opt/build/repo/plugins/v-tooltip.ts";
import vfm_ojUFbAk57O9HV622CeHpBHQbuPGsW1sawboN_97LjBg from "/opt/build/repo/plugins/vfm.ts";
import vue_awesome_paginate_client_M8KAsW5H_K5bYwTCSCXtlK3h8cQWmo_vpPkZFyikPHc from "/opt/build/repo/plugins/vue-awesome-paginate.client.ts";
import vue_collapse_BdVLwSwsCGoskVYyUYz9Q4ENGXReLagmxAoDc4m_U6c from "/opt/build/repo/plugins/vue-collapse.ts";
import vue3_touch_events_client_LshEL6EetSD_9Vxfb_vLJo19SCXRm613oH9AVp6oz88 from "/opt/build/repo/plugins/vue3-touch-events.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  plugin_client_w8gOGEJbCd_zll3tePMHpSlPcNH1LmVhU_SfzQ0BgV4,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4,
  router_scroll_client_E0tcc_fkye7v1ZcB1kkZiNJryJupTs7MSej_ltDkW88,
  swiper_client_NrOBMhFIWYHsdM_q16aZBFnWaXK9VtE1Z_V7xAdrtO0,
  v_tooltip_GcZrMDzhZrP65RfEXeZdHDIsPaRz4FoykeN4qcrCdxo,
  vfm_ojUFbAk57O9HV622CeHpBHQbuPGsW1sawboN_97LjBg,
  vue_awesome_paginate_client_M8KAsW5H_K5bYwTCSCXtlK3h8cQWmo_vpPkZFyikPHc,
  vue_collapse_BdVLwSwsCGoskVYyUYz9Q4ENGXReLagmxAoDc4m_U6c,
  vue3_touch_events_client_LshEL6EetSD_9Vxfb_vLJo19SCXRm613oH9AVp6oz88
]