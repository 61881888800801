<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockForm } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers";
import { isTypeFormDynamicFields } from "~/types/TypeFormDynamicFields";

defineProps<{
  fields: TypeBlockForm<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const success = ref(false);
</script>

<template>
  <UiContainer class="py-4">
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-9">
      <div class="wysiwyg">
        <h1 class="mb-3">
          {{ fields.title }}
        </h1>
        <RichTextRenderer
          :document="fields.copy"
          :node-renderers="nodeRenderers"
        />
      </div>
      <div
        v-if="fields.formEndpoint"
        class="flex items-center bg-ui-grey4 px-7 py-5"
      >
        <FormDynamic
          v-if="!success && isTypeFormDynamicFields(fields.formFields)"
          class="w-full"
          :form-endpoint="fields.formEndpoint"
          :form-fields="fields.formFields"
          @success="success = true"
        />
        <RichTextRenderer
          v-else
          :document="fields.successCopy"
          :node-renderers="nodeRenderers"
        />
      </div>
    </div>
  </UiContainer>
</template>
