<script setup lang="ts">
import { FetchError } from "ofetch";
import type { TypeUser } from "~/types/auth";

const route = useRoute();

const contentConfig = useContentConfig();

const successMessage = ref("");

const errorMessage = ref("");

const form = ref<HTMLFormElement>();

const passcodeSent = ref("");

const usePasscode = ref(false);

const pending = ref(false);

const loggedIn = ref(false);

const params = route.query.nav;

const passcodeInput = (event: Event) => {
  (event.target as HTMLInputElement).value = (
    event.target as HTMLInputElement
  ).value.toUpperCase();

  (
    (event.target as HTMLInputElement).nextSibling as HTMLInputElement | null
  )?.focus();

  if (checkPasscode()) {
    login(event, true);
  }
};

const passcodePaste = (event: ClipboardEvent) => {
  const code = event.clipboardData?.getData("text").toUpperCase();

  if (!code) {
    return;
  }

  let input = event.target as HTMLInputElement | null;

  for (const char of code) {
    if (!input) break;

    input.value = char;

    input = input.nextSibling as HTMLInputElement | null;
  }

  if (checkPasscode()) {
    login(event, true);
  }
};

const checkPasscode = () => {
  const digits = [];

  if (form.value?.elements) {
    for (const element of form.value.elements) {
      const inputElement = element as HTMLInputElement;

      if (
        inputElement.name.startsWith("passcode")
        && inputElement.value.length
      ) {
        digits.push(inputElement.value);
      }
    }
  }

  return digits.length === 6;
};

const login = (_event: Event, loginPasscode = false) => {
  if (!form.value?.reportValidity()) {
    return;
  }

  const formData = new FormData(form.value);

  successMessage.value = "";

  errorMessage.value = "";

  pending.value = true;

  $fetch<string | TypeUser>("/api/auth/login", {
    method: "POST",
    body: {
      ...Object.fromEntries(formData.entries()),
      loginPasscode,
      loginPassword: !!formData.get("password") && !loginPasscode,
    },
  })
    .then(async (data) => {
      if (data === "login-passcode-sent") {
        passcodeSent.value = String(formData.get("email"));

        nextTick(() =>
          document.querySelector<HTMLInputElement>("[name=passcode0]")?.focus(),
        );

        usePasscode.value = true;
      } else if (typeof data !== "string") {
        useUser().value = data;

        /* Setting SF Account data after login to avoid loading race issues */
        if (useUser().value) {
          const { data: accountData } = await useFetch("/api/auth/account");
          useAccount().value = accountData.value
            ? {
                ...accountData.value,
                membershipDate: new Date(accountData.value.membershipDate),
                relationships: accountData.value.relationships.map((relationship) => ({
                  ...relationship,
                  date: new Date(relationship.date),
                })),
              }
            : null;
        }

        loggedIn.value = true;

        // Clear inputs to stop them reappearing after logout
        const emailInput = form.value?.elements.namedItem("email");

        if (emailInput) (emailInput as HTMLInputElement).value = "";

        const passwordInput = form.value?.elements.namedItem("password");

        if (passwordInput) (passwordInput as HTMLInputElement).value = "";

        const nextRoute = (params) ? "/dashboard" : useRouter().options.history.state.back?.toString() ?? "/";
        await navigateTo(nextRoute);
      }

      turnstile.value?.reset();

      pending.value = false;
    })
    .catch((error: unknown) => {
      if (error instanceof FetchError) {
        errorMessage.value = error.data.message;
      }

      turnstile.value?.reset();

      pending.value = false;
    });
};

const turnstile = ref();

const turnstileToken = ref("");

onMounted(() => setTimeout(() => {
  if (useScriptCloudflareTurnstile().status.value === "error") {
    errorMessage.value = "Your browser does not support bot detection. Please whitelist challenges.cloudflare.com or try another browser.";
  }
}, 2000));
</script>

<template>
  <div
    v-if="!loggedIn"
    class="my-5 sm:mx-4 md:my-6"
  >
    <div
      class="mx-auto max-w-[696px] rounded-sm bg-level-standard px-3 py-4 text-center md:px-8 md:py-5"
    >
      <h1 class="mb-4">
        Login to FAIRR Portal
      </h1>
      <span
        v-if="errorMessage"
        class="error"
      >{{ errorMessage }}</span>
      <span
        v-if="successMessage"
        class="success"
      >{{ successMessage }}</span>
      <form
        ref="form"
        @submit.prevent="login"
      >
        <input
          type="hidden"
          name="pardotLoginFormEndpoint"
          :value="contentConfig?.pardotLoginFormEndpoint"
        />
        <div
          v-if="!usePasscode || !passcodeSent"
          class="form-floating mb-2"
        >
          <input
            id="email-input"
            type="email"
            name="email"
            placeholder="Email Address*"
            aria-label="Email Address*"
            required
          />
          <label for="email-input">Email Address</label>
        </div>
        <div
          v-if="!usePasscode"
          class="form-floating mb-2"
        >
          <input
            id="password-input"
            type="password"
            name="password"
            placeholder="Password"
            aria-label="Password"
          />
          <label for="password-input">Password</label>
        </div>
        <template v-if="passcodeSent">
          <div>Please enter the passcode sent to {{ passcodeSent }}</div>
          <input
            type="hidden"
            name="email"
            :value="passcodeSent"
          />
          <div class="mt-4 flex justify-center gap-3">
            <input
              name="passcode0"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
            <input
              name="passcode1"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
            <input
              name="passcode2"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
            <input
              name="passcode3"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
            <input
              name="passcode4"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
            <input
              name="passcode5"
              type="tel"
              class="text-center text-[2rem] font-bold uppercase"
              maxlength="1"
              required
              @input="passcodeInput"
              @paste="passcodePaste"
            />
          </div>
        </template>
        <div
          v-if="!usePasscode"
          class="wysiwyg text-left text-sm"
        >
          <NuxtLink to="/reset-password">
            Forgotten your password?
          </NuxtLink>
        </div>
        <NuxtTurnstile
          ref="turnstile"
          v-model="turnstileToken"
          :options="{
            'error-callback': (errorCode: string) => {
              errorMessage = `Your browser failed bot detection, please try again or contact investoroutreach@fairr.org and quote error code ${errorCode}.`;

              turnstileToken = 'failure';
            },
            'appearance': 'interaction-only',
          }"
          class="mt-4"
        />
        <div class="mt-4 flex items-center justify-center gap-3">
          <ClientOnly>
            <button
              v-if="!usePasscode"
              class="btn w-[120px]"
              type="submit"
              :disabled="!turnstileToken || pending"
            >
              Login
            </button>
            <button
              v-else
              class="btn w-[120px]"
              type="button"
              :disabled="!turnstileToken || pending"
              @click="
                () => {
                  usePasscode = false;
                  errorMessage = '';
                  passcodeSent = '';
                }
              "
            >
              Go back
            </button>
            <button
              v-if="!usePasscode || !passcodeSent"
              class="btn btn--secondary"
              type="button"
              :disabled="!turnstileToken || pending"
              @click="
                (event: Event) => {
                  if (
                    (usePasscode && form?.reportValidity())
                    || form?.checkValidity()
                  ) {
                    login(event, true);
                  }
                  else {
                    usePasscode = true;
                  }
                }
              "
            >
              or email me a passcode
            </button>
            <button
              v-if="usePasscode && passcodeSent"
              class="btn w-[120px]"
              type="button"
              :disabled="!turnstileToken || pending"
              @click="
                (event: Event) => {
                  if (form?.reportValidity()) {
                    passcodeInput(event);
                  }
                }
              "
            >
              Login
            </button>
          </ClientOnly>
        </div>
        <div class="wysiwyg mt-4">
          Not a member? <NuxtLink to="/signup">
            Register
          </NuxtLink> to access the
          data
        </div>
      </form>
    </div>
  </div>
</template>
